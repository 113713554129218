import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";
import lodash from "lodash";
import {
  Switch,
  Typography,
  CardContent,
  Card,
  Checkbox,
  ListItem,
} from "@material-ui/core";
import {
  toolsData,
  inActiveTooltipCleanupDesc,
  activeTooltipCleanupDesc,
  membersSettings,
} from "./toolsData";
import {
  accountCleanup,
  getMcToCcbCustomList,
  saveAutoSync,
  saveCheckReceivedEmail,
  saveDeleteMcListGroup,
  saveMcToCcbRemoveInactive,
  saveMemberSettings,
  savePrimaryProfiles,
  saveSkipMergeValidation,
  saveStoreUserInfo,
  saveToolkitCleanup,
} from "../../store/middlewares/toolsMiddleware";
import { setSnackbarData } from "../../store/actions/globalAction";
import { deepClone, formDataWithApiKey } from "../../helpers";
import Loader from "../../components/Loader/Loader";
import ButtonWithSpinner from "../../components/ButtonWithSpinner/ButtonWIthSpinner";
import { clearToolPageState } from "../../store/actions/toolsActions";
import ConfirmationModal from "../../components/modals/ConfirmationModal/ConfirmationModal";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import ToolsMakeExceptions from "./ToolsMakeExceptions";
import ToolsExceptionModal from "../../components/ToolsPageComponents/ToolsExceptionModal/ToolsExceptionModal";
import "../../styles/components/dashboard/dashboard-main.scss";
import "../../styles/views/authorize-account.scss";
import "../../styles/views/login.scss";

const Tools = (props) => {
  const {
    // Component props
    history,

    // Redux props
    userData,
    isLoading,
    errorMessage,
    successMessage,
    mcToCcbCustomList,
    getMcToCcbCustomListStatus,

    // Redux functions
    saveToolkitCleanup,
    saveStoreUserInfo,
    savePrimaryProfiles,
    saveMcToCcbRemoveInactive,
    saveDeleteMcListGroup,
    saveMembersSettings,
    accountCleanup,
    setSnackbarData,
    clearToolPageState,
    saveAutoSync,
    saveSkipMergeValidation,
    saveCheckReceivedEmail,
    getMcToCcbCustomList,
  } = props;
  const [tools, setTools] = useState({});
  const [list, setList] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [showToolsExceptionModal, setShowToolsExceptionModal] = useState(false);

  const isSidebarExpanded = useIsSidebarExpanded();

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const setListELements = useCallback(() => {
    if (!list.length) {
      const userDataKeys = Object.keys(userData);
      membersSettings.forEach((element) => {
        userDataKeys.forEach((item) => {
          if (element.id === item) {
            element.checked = Number(userData[item]);
          }
        });
      });
      setList(membersSettings);
    }
  }, [list, userData]);

  const setToolkipCleanupData = useCallback(
    (tool) => {
      tool["leftColTools"][0].isEnable = userData?.isToolkitCleanup;
      if (userData?.isToolkitCleanup === 1) {
        tool["leftColTools"][0].description = activeTooltipCleanupDesc;
      } else {
        tool["leftColTools"][0].description = inActiveTooltipCleanupDesc;
      }
    },
    [userData]
  );

  const setSwitchValuesFromRedux = useCallback((tool, col, index, value) => {
    tool[col][index].isEnable = value;
  }, []);

  useEffect(() => {
    const formData = formDataWithApiKey();
    getMcToCcbCustomList(formData);
  }, [getMcToCcbCustomList]);

  useEffect(() => {
    // SAVE LIST
    if (!lodash.isEmpty(userData)) {
      setListELements();
    }
  }, [setListELements, userData]);

  const setDataInState = useCallback(() => {
    const allTools = { ...toolsData };
    // SET DATA IN TOOLKIT CLEANUP TOOL
    setToolkipCleanupData(allTools);

    // SAVE OTHER RIGHTHAND SIDE COL TOOLS SWITCH STATE
    setSwitchValuesFromRedux(
      allTools,
      "rightColTools",
      0, // Index of the tool
      userData?.isStoreUserInfo
    );

    setSwitchValuesFromRedux(
      allTools,
      "rightColTools",
      1, // Index of the tool
      userData?.isAutoSync
    );

    setSwitchValuesFromRedux(
      allTools,
      "rightColTools",
      2, // Index of the tool
      userData?.skipMergeValidation
    );

    setSwitchValuesFromRedux(
      allTools,
      "rightColTools",
      3, // Index of the tool
      userData?.isMcToCcbDisableInactiveRemove
    );

    setSwitchValuesFromRedux(
      allTools,
      "rightColTools",
      4, // Index of the tool
      userData?.isDeleteMcListGroup
    );

    // SAVE OTHER LEFTHAND SIDE COL TOOLS SWITCH STATE
    setSwitchValuesFromRedux(
      allTools,
      "leftColTools",
      1, // Index of left tool
      userData?.isCheckPrimaryProfiles
    );

    setSwitchValuesFromRedux(
      allTools,
      "leftColTools",
      2, // index of left tool
      userData?.is_check_received_email_from_ccb
    );
    setTools(allTools);
  }, [setSwitchValuesFromRedux, setToolkipCleanupData, userData]);

  useEffect(() => {
    // SET tools switch values in state
    if (!lodash.isEmpty(userData)) {
      setDataInState();
    }
  }, [setDataInState, userData]);

  useEffect(() => {
    if (errorMessage) {
      setErrorModal(true);
    } else if (successMessage) {
      setDataInState();
      showSnackbar(successMessage, "success");
      clearToolPageState();
    }
  }, [
    clearToolPageState,
    errorMessage,
    history,
    showSnackbar,
    successMessage,
    setDataInState,
  ]);

  const handleSaveMembersSettings = useCallback(() => {
    const formData = formDataWithApiKey();
    list.forEach((item) => formData.append(item.id, item.checked));
    saveMembersSettings(formData);
  }, [list, saveMembersSettings]);

  const handleSwitchValueChange = useCallback(
    (tool) => {
      const formData = formDataWithApiKey();
      formData.append("val", tool.isEnable === 0 ? 1 : 0);
      if (tool.identity === "isToolkitCleanup") {
        saveToolkitCleanup(formData);
      } else if (tool.identity === "isStoreUserInfo") {
        saveStoreUserInfo(formData);
      } else if (tool.identity === "isCheckPrimaryProfiles") {
        savePrimaryProfiles(formData);
      } else if (tool.identity === "is_check_received_email_from_ccb") {
        saveCheckReceivedEmail(formData);
      } else if (tool.identity === "isMcToCcbDisableInactiveRemove") {
        saveMcToCcbRemoveInactive(formData);
      } else if (tool.identity === "isDeleteMcListGroup") {
        saveDeleteMcListGroup(formData);
      } else if (tool.identity === "isAutoSync") {
        saveAutoSync(formData);
      } else if (tool.identity === "skipMergeValidation") {
        saveSkipMergeValidation(formData);
      }
    },
    [
      saveAutoSync,
      saveCheckReceivedEmail,
      saveDeleteMcListGroup,
      saveMcToCcbRemoveInactive,
      savePrimaryProfiles,
      saveSkipMergeValidation,
      saveStoreUserInfo,
      saveToolkitCleanup,
    ]
  );

  const handleCheckboxClicked = useCallback(
    (listIndex, value) => {
      const cloneList = deepClone(list);
      cloneList[listIndex].checked = value;
      setList(cloneList);
    },
    [list]
  );

  const handleButtonClicked = useCallback(
    (tool) => {
      const formData = formDataWithApiKey();
      if (tool.title.includes("Ignored")) {
        history.push("/tools/Ignored-emails");
      } else if (tool.identity === "memberSettings") {
        handleSaveMembersSettings();
      } else if (tool.identity === "isToolkitCleanup") {
        accountCleanup(formData);
      } else if (tool.identity === "isCheckPrimaryProfiles") {
        history.push("/tools/primary-contacts");
      } else if (tool.identity === "isDeleteMcListGroup") {
        history.push("/tools/confirm-delete");
      } else if (tool.identity === "isStoreUserInfo") {
        history.push("/header-mapping");
      }
    },
    [accountCleanup, handleSaveMembersSettings, history]
  );

  const toggleErrorModal = useCallback(() => {
    setErrorModal(!errorModal);
    clearToolPageState();
  }, [errorModal, clearToolPageState]);

  const toggleToolExceptionModal = useCallback(() => {
    setShowToolsExceptionModal(!showToolsExceptionModal);
  }, [showToolsExceptionModal]);

  const renderList = useCallback(
    (tool) => {
      if (tool.isListTool) {
        return list.map((item, listIndex) => (
          <ListItem className="p-0">
            <div className="flexer-start">
              <Checkbox
                value={item.title}
                checked={item.checked === 1 ? true : false}
                name={item.title}
                onChange={() =>
                  handleCheckboxClicked(listIndex, item.checked === 1 ? 0 : 1)
                }
                style={{ color: "#2db8e8" }}
              />
              <Typography variant="subtitle2">{item.title}</Typography>
            </div>
          </ListItem>
        ));
      }
    },
    [handleCheckboxClicked, list]
  );

  return (
    <div
      className={clsx("dashboard-main", {
        "with-sidebar-expanded": isSidebarExpanded,
        "with-sidebar-collapsed": !isSidebarExpanded,
      })}
    >
      <div className="cards-wrapper w-100">
        <h3 className="user-note text-center mt-4">Toolkit</h3>
        <div className="tools-page-container">
          <div className="w-48">
            {!lodash.isEmpty(tools) &&
              tools["leftColTools"].map((tool, index) => (
                <Card
                  variant="outlined"
                  key={index}
                  className={`account-activation-card w-100 ${
                    index !== 0 ? "mt-4" : ""
                  }`}
                >
                  <CardContent>
                    <div className="flexer-start">
                      {tool.hasSwitch && (
                        <Switch
                          checked={tool.isEnable === 1 ? true : false}
                          className="align-self-start mr-2"
                          onChange={() => handleSwitchValueChange(tool)}
                        />
                      )}
                      <Typography
                        className={`${
                          tool.isListTool ? "font-weight-bold" : ""
                        }`}
                        variant="subtitle2"
                      >
                        {tool.title} <span>{tool.helpIcon}</span>
                      </Typography>
                    </div>
                    <div className="mt-1">
                      <Typography variant="subtitle2">
                        {tool.description}
                      </Typography>
                      {tool.warning && (
                        <Typography className="mt-2" variant="subtitle2">
                          {tool.warning}
                        </Typography>
                      )}
                    </div>
                    {renderList(tool, index)}
                    {tool.hasButton && tool.isEnable === 1 && (
                      <div className="flexer">
                        <ButtonWithSpinner
                          onClick={() => handleButtonClicked(tool)}
                          className="bg-yellow mt-2"
                        >
                          {tool.btnLabel}
                        </ButtonWithSpinner>
                        {!lodash.isEmpty(tool.link) && (
                          <div className="flexer ml-3 cursor-pointer">
                            <Typography
                              onClick={() => history.push("/logs")}
                              variant="subtitle2"
                            >
                              {tool?.link?.icon} {tool.link?.label}
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              ))}
          </div>
          <div className="w-48">
            {!lodash.isEmpty(tools) &&
              tools["rightColTools"].map((tool, index) => (
                <Card
                  variant="outlined"
                  key={index}
                  className={`account-activation-card w-100 ${
                    index !== 0 ? "mt-4" : ""
                  }`}
                >
                  <CardContent>
                    <div className="flexer-start">
                      {tool.hasSwitch && (
                        <Switch
                          checked={tool.isEnable === 1 ? true : false}
                          className="align-self-start mr-2"
                          onChange={() => handleSwitchValueChange(tool)}
                        />
                      )}
                      <Typography
                        className={`${
                          tool.isListTool ? "font-weight-bold" : ""
                        }`}
                        variant="subtitle2"
                      >
                        {tool.title}
                      </Typography>
                    </div>
                    <Typography className="mt-1" variant="subtitle2">
                      {tool.description}
                    </Typography>
                    {tool?.withMakeException &&
                      tool?.identity === "isMcToCcbDisableInactiveRemove" &&
                      tool.isEnable === 1 && (
                        <ToolsMakeExceptions
                          getMcToCcbCustomListStatus={
                            getMcToCcbCustomListStatus
                          }
                          mcToCcbCustomList={mcToCcbCustomList}
                          onClick={toggleToolExceptionModal}
                        />
                      )}
                    {tool.hasButton && tool.isEnable === 1 && (
                      <div className="flexer">
                        <ButtonWithSpinner
                          onClick={() => handleButtonClicked(tool)}
                          className="bg-yellow mt-2"
                        >
                          {tool.btnLabel}
                        </ButtonWithSpinner>
                      </div>
                    )}
                  </CardContent>
                </Card>
              ))}
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      {errorModal && (
        <ConfirmationModal
          open={errorModal}
          handleClose={toggleErrorModal}
          modalHeading={"Error"}
          modalContent={ReactHtmlParser(errorMessage)}
          btnLabel={"Ok"}
          showCancelBtn={false}
          btnClassName={"bg-yellow w-30 mb-2  mr-3"}
          onClick={toggleErrorModal}
        />
      )}
      {showToolsExceptionModal && (
        <ToolsExceptionModal
          open={showToolsExceptionModal}
          onClose={toggleToolExceptionModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    isLoading: store.tools.isLoading,
    errorMessage: store.tools.errorMessage,
    successMessage: store.tools.successMessage,
    mcToCcbCustomList: store.tools.mcToCcbCustomList,
    getMcToCcbCustomListStatus: store.tools.getMcToCcbCustomListStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveToolkitCleanup: (data) => dispatch(saveToolkitCleanup(data)),
    saveStoreUserInfo: (data) => dispatch(saveStoreUserInfo(data)),
    savePrimaryProfiles: (data) => dispatch(savePrimaryProfiles(data)),
    saveMcToCcbRemoveInactive: (data) =>
      dispatch(saveMcToCcbRemoveInactive(data)),
    saveDeleteMcListGroup: (data) => dispatch(saveDeleteMcListGroup(data)),
    saveMembersSettings: (data) => dispatch(saveMemberSettings(data)),
    accountCleanup: (data) => dispatch(accountCleanup(data)),
    saveAutoSync: (data) => dispatch(saveAutoSync(data)),
    saveSkipMergeValidation: (data) => dispatch(saveSkipMergeValidation(data)),
    saveCheckReceivedEmail: (data) => dispatch(saveCheckReceivedEmail(data)),
    getMcToCcbCustomList: (data) => dispatch(getMcToCcbCustomList(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearToolPageState: (snackbarData) =>
      dispatch(clearToolPageState(snackbarData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
